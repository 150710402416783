import React, { useCallback, useState } from 'react';
import cls from 'classnames';
import Image from 'next/image';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useRouter } from 'next/router';
import { traceEvent } from '@wk/wk-gatherer';
import clockIcon from '@public/imgs/clock-icon-new.svg';
import personIcon from '@public/imgs/icon-profile.svg';
import learnIcon from '@public/imgs/icon-learn-center.svg';
import timeIcon from '@public/imgs/icon-course.svg';
import subscriptionIcon from '@public/imgs/icon-subscription.svg';
import voucherIcon from '@public/imgs/voucher.svg';
import orderIcon from '@public/imgs/order.svg';
import cogniaIcon from '@public/imgs/icon-cognia.svg';
import passwordIcon from '@public/imgs/icon-password.svg';
import exitIcon from '@public/imgs/icon-logout.svg';
import arrowBottomIcom from '@public/imgs/pc/arrow-bottom-icon.svg';
import languageIcon from '@public/imgs/pc/language-icon.svg';
import backIcon from '@public/imgs/pc/icon-back.svg';
import leaveIcon from '@public/imgs/pc/icon-leave.svg';
import User from '../User';
import { Dropdown } from '@/components';
import PATHS from '@/utils/paths';
import { generateNamePrefix } from '@/utils/helpers';
import { CURRENT_LANGUAGE, LanguageList } from '@/utils/constants';
import styles from './style.module.scss';
import cacheStorage from '@/utils/cacheStorage';
import { StudentInfo, SubscriptionEsRes } from '@/api/types/home';
import ChangePwd from '../ChangePwd';
import { log } from '@/api/log';
import { reserveSubject } from '@/api/reserve';
import { isTourist } from '@/utils/utils';
import { WKButton, WKModal } from '@wk/components';
import Cookie from 'js-cookie';
import useUser from '@/store/useUser';
import useTraceEventWithAccount from '@/utils/hooks/useTraceEventWithAccount';
import { GetUnReadMessageNumRes } from '@/api/types/message';
import { useUpdateEffect } from 'react-use';
import useNews from '@/store/useNews';
import { getUnReadMessageNum } from '@/api/message';
import MobileHeader from '../MobileHeader';
import useDeviceType from '@/utils/hooks/useDeviceType';
import { CANCEL_COURSE_STEP } from '@/pages/cancel-course';
import { STUDENT_LIST } from '@/pageComponents/Profile/Info';
import CalendarTip from '../CalendarTip';

dayjs.extend(utc);
dayjs.extend(timezone);

interface HeaderProps {
  canBack: boolean;
  timezone?: string;
  timezoneStd?: string;
  onLogout: (routerParams?: string) => void;
  curStudent: StudentInfo | null;
  students: StudentInfo[] | null;
  setCurStudent: (data: StudentInfo) => void;
  showStudentCenter?: boolean;
  showReserveModal?: boolean;
  showHeaderBack?: boolean;
  canSwitchStudent?: boolean;
  handleCustomBack?: ((_: any) => void) | null | undefined;
  showLanguageSwitching?: boolean;
  showTools?: boolean;
  showLanguageDrop?: boolean;
  hiddenTimezone?: boolean;
  timezoneCanClick?: boolean;
  stuScheduleContent?: SubscriptionEsRes | null;
  calendarTip?: boolean;
  closeCalendarTip?: (visible: boolean) => void;
}

const prefixName = generateNamePrefix('home.');

const Header: React.FC<HeaderProps> = (props) => {
  const {
    canBack,
    timezoneStd = '',
    onLogout,
    curStudent,
    students,
    showStudentCenter,
    showReserveModal,
    setCurStudent,
    showHeaderBack,
    handleCustomBack,
    canSwitchStudent = false,
    showLanguageSwitching = true,
    showTools = true,
    showLanguageDrop = true,
    stuScheduleContent,
    hiddenTimezone = false,
    timezoneCanClick = true,
    calendarTip = false,
    closeCalendarTip,
    ...restProps
  } = props || {};

  const { account } = useUser();
  const router = useRouter();
  const { t, i18n } = useTranslation('home');
  const { t: appointmentT } = useTranslation('appointment');
  const [pwdVisible, setPwdVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { traceEventWithAccount } = useTraceEventWithAccount();
  const isMobile = useDeviceType();

  const { setUnReadMessageInfo } = useNews();

  useUpdateEffect(() => {
    if (!!students?.length) {
      getUnReadMessageNum().then((res: GetUnReadMessageNumRes['content']) => {
        setUnReadMessageInfo(res);
      });
    }
  }, [students]);

  const { subject, source, showCalendarModal: qShowCalendarModal } = useRouter().query;

  const memorizedHandleModalClose = useCallback(() => {
    setShowModal(false);
    traceEvent({
      data: {
        _event: 'U_BookingPop_KeeponClick',
        subject: subject ? subject : window.sessionStorage.getItem('curSubject'),
        account_id: account?.uuid,
        booking_triggerevent: source ? 'web_official' : router.query?.booking_triggerevent,
        trigger_pvid: Cookie.get('trigger_pvid'),
        login_triggerevent: Cookie.get('login_triggerevent'),
        login_method: Cookie.get('login_type'),
      },
    });
  }, [account?.uuid, router.query.booking_triggerevent, source, subject]);

  const handleChangePwd = () => {
    setPwdVisible(true);
  };

  const handleBack = () => {
    sessionStorage.removeItem(CANCEL_COURSE_STEP);
    if (showReserveModal) {
      setShowModal(true);
    } else {
      router.replace(PATHS.home).then();
    }
  };

  const handleBackCustom = (e: any) => {
    if (handleCustomBack) {
      handleCustomBack(e);
    } else {
      router.back();
    }
  };

  const onSwitchStudent = (student: StudentInfo) => {
    setCurStudent(student);
    const NEED_REFRESH_ROUTES = [PATHS.playback];
    const { pathname } = router;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    NEED_REFRESH_ROUTES?.includes(pathname) && router.replace(PATHS.curriculum);
  };

  const handleToSubscriptionDetail = () => {
    const data = {
      _event: 'U_HP_SubscribeEnterClick',
      teacherId: '',
      courseStatus: '',
      studentType: 'GS2.0',
    };
    traceEvent({
      data,
    });
    router.push({
      pathname: `/home/subscriptionDetails`,
      query: { triggerEvent: 'U_HP_SubscribeEnterClick' },
    });
  };

  if (isMobile && router.pathname.split('/').includes('cashier-desk')) {
    return <MobileHeader onLogout={onLogout} />;
  }

  return (
    <>
      <CalendarTip visible={calendarTip} close={closeCalendarTip} showCalendarModal={Boolean(qShowCalendarModal)} />
      <div className={styles.headerWrapper}>
        <section className={styles.leftContainer}>
          {showStudentCenter && (
            <div style={{ opacity: canBack ? '1' : '.3' }} className={styles.back}>
              <div role="button" onClick={canBack ? handleBack : undefined}>
                {/* <Image src={backIcon} /> */}
                {/* <span>{t('学员中心')}</span> */}
              </div>
            </div>
          )}
          {showHeaderBack && (
            <div className={styles.back}>
              <div role="button" onClick={handleBackCustom}>
                <Image src={backIcon} alt={'WK'} />
              </div>
            </div>
          )}
          {pwdVisible && (
            <ChangePwd
              visible={pwdVisible}
              onChangeVisible={setPwdVisible}
              curStudent={curStudent}
              onLogout={onLogout}
            />
          )}
          {!isTourist() && timezoneStd && !hiddenTimezone && (
            <div
              role="button"
              style={{ display: 'flex', alignItems: 'center', cursor: timezoneCanClick ? 'pointer' : 'unset' }}
              onClick={() => {
                if (!timezoneCanClick) return;
                traceEventWithAccount({
                  _event: 'U_PC_TimeAreaClick',
                });
                router.push({
                  pathname: PATHS.profile,
                  query: { anchor: STUDENT_LIST },
                });
              }}
            >
              <div className={styles.icon}>
                <Image src={clockIcon} alt={'WK'} />
              </div>
              <span className={styles.timezone}>{`${restProps.timezone?.substring(
                0,
                restProps?.timezone?.indexOf(')') + 1,
              )} ${timezoneStd}`}</span>
            </div>
          )}
        </section>
        {showLanguageSwitching && (
          <section className={styles.rightContainer}>
            {stuScheduleContent?.display && (
              <div className={styles.subscribeBtn} onClick={handleToSubscriptionDetail}>
                {i18n.language === 'zh' ? '订阅' : 'Subscribe'}
              </div>
            )}
            {canSwitchStudent && <User curStudent={curStudent} students={students} onSwitch={onSwitchStudent} />}
            {showLanguageDrop && (
              <Dropdown
                optionClassName={styles.dropdownContainer}
                optionChildren={
                  <div className={styles.languageBox}>
                    {LanguageList?.map(({ label, value }) => (
                      <div
                        key={value}
                        role="button"
                        className={cls(styles.languageItem, i18n.language === value && styles.languageItemActive)}
                        onClick={() => {
                          if (i18n.language !== value) {
                            traceEventWithAccount({
                              _event: 'U_PC_LanguageClick',
                              language: value,
                            });
                            cacheStorage.setItem(CURRENT_LANGUAGE, value);
                            router.replace(router.asPath, router.asPath, {
                              locale: value,
                            });
                          }
                        }}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                }
              >
                <div className={styles.languageContainer}>
                  <span className={styles.languageIcon}>
                    <Image src={languageIcon} alt={'WK'} />
                  </span>
                  {LanguageList?.find(({ value }) => value === i18n.language)?.label || ''}
                  <span className={styles.arrowIcon}>
                    <Image src={arrowBottomIcom} alt={'WK'} />
                  </span>
                </div>
              </Dropdown>
            )}
            {/* <div
        onClick={() => toMessagePage()}
        className="ml-[20px] bg-[#f7f7f7] rounded-[50%] flex items-center justify-center cursor-pointer w-[32px] relative"
      >
        {!!unReadMessageInfo && <Bell />}
        {(unReadMessageInfo?.courseCount > 0 || unReadMessageInfo?.systemCount > 0) && (
          <RedPoint className="absolute top-[5px] right-[6px]" />
        )}
      </div> */}
            {showTools && (
              <Dropdown
                className={styles.person}
                optionClassName={cls(styles.dropdownContainer, styles.misc)}
                optionChildren={
                  <>
                    {!isTourist() && (
                      <>
                        <Link href={{ pathname: PATHS.profile }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEventWithAccount({ _event: 'U_PC_PersonalCenterClick' });
                              log(prefixName`enter_profile`);
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={personIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('个人中心')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.learnCenter }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEventWithAccount({
                                _event: 'U_HP_StudyCenterEnterClick',
                                // subject: learnSituationData?.map(({ subject }) => subject)?.join(','),
                                // level1: learnSituationData?.map(({ level1 }) => level1)?.join(','),
                              });
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={learnIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('学情中心')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.period }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => log(prefixName`enter_period`)}
                          >
                            <span className={styles.icon}>
                              <Image src={timeIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('课时信息')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.vouchers }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEvent({
                                data: {
                                  _event: 'U_HomePage_VoucherClick',
                                  Code: curStudent?.code,
                                },
                              });
                              log(prefixName`enter_period`);
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={voucherIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('我的代金券')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.orderCenter }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEvent({
                                data: {
                                  _event: 'U_HomePage_OderCenterClick',
                                  Code: curStudent?.code,
                                },
                              });
                              log(prefixName`enter_period`);
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={orderIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('我的订单')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.subscription }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEvent({
                                data: {
                                  _event: 'U_HomePage_MySubscriptionClick',
                                  Code: curStudent?.code,
                                },
                              });
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={subscriptionIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('我的订阅')}</span>
                          </div>
                        </Link>
                        <Link href={{ pathname: PATHS.cognia }}>
                          <div
                            className={styles.personItem}
                            role="button"
                            onClick={() => {
                              traceEventWithAccount({
                                _event: 'U_PersonalCenter_CogniaApplyClick',
                              });
                            }}
                          >
                            <span className={styles.icon}>
                              <Image src={cogniaIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('申请Cognia证书')}</span>
                          </div>
                        </Link>
                        <div className={styles.personItem} role="button" onClick={handleChangePwd}>
                          <span className={styles.icon}>
                            <Image src={passwordIcon} alt={'WK'} />
                          </span>
                          <span className={styles.title}>{t('修改密码')}</span>
                        </div>
                        <Link href={{ pathname: PATHS.leave }}>
                          <div className={styles.personItem} role="button">
                            <span className={styles.icon}>
                              <Image src={leaveIcon} alt={'WK'} />
                            </span>
                            <span className={styles.title}>{t('请假记录')}</span>
                          </div>
                        </Link>
                      </>
                    )}
                    <div
                      className={styles.personItem}
                      role="button"
                      onClick={() => {
                        log(prefixName`logout`);
                        onLogout();
                      }}
                    >
                      <span className={styles.icon}>
                        <Image src={exitIcon} alt={'WK'} />
                      </span>
                      <span className={styles.title}>{t('退出登录')}</span>
                    </div>
                  </>
                }
              >
                <span className={styles.account} />
              </Dropdown>
            )}
          </section>
        )}
        <WKModal showClose={false} open={showModal} onCancel={memorizedHandleModalClose}>
          {/* <div className={styles.modalContent}>
        <p className={styles.modalTitle}>约课遇到问题?</p>
        <p className={styles.modalSub}>扫码添加专属学习规划师，为您解决问题</p>
        <div className={styles.qrcodeBox}>
          <div className={styles.qrItem}>
            <div className={styles.code}>
              <Image src={whatsAppsImg} alt="WhatsApp" layout="responsive" />
            </div>
            <span>{t('自主化-上课须知-添加学习规划师-whatsApp')}</span>
          </div>
          <div className={styles.qrItem}>
            <div className={styles.code}>
              <Image src={wechatImg} alt="WeChat" layout="responsive" />
            </div>
            <span>{t('自主化-上课须知-添加学习规划师-wechat')}</span>
          </div>
        </div>
        <WKButton type={'primary'} className={styles.continueAppointBtn} onClick={memorizedHandleModalClose}>
          {appointmentT('继续约课')}
        </WKButton>
        <WKButton
          type={'text'}
          style={{ width: '100%', fontSize: '14px' }}
          onClick={() => {
            const reserveForm = window.sessionStorage.getItem('ReserveForm')
              ? JSON.parse(window.sessionStorage.getItem('ReserveForm') ?? '')
              : null;
            const params =
              reserveForm?.subject === 'math'
                ? { grade: `${reserveForm?.grade}` }
                : { chineseLevel: `${reserveForm?.grade}` };
            traceEvent({
              data: {
                _event: 'U_BookingPop_GaveUpClick',
                subject: subject ? subject : window.sessionStorage.getItem('curSubject'),
                account_id: account?.uuid,
                booking_triggerevent: source ? 'web_official' : router.query?.booking_triggerevent,
                trigger_pvid: Cookie.get('trigger_pvid'),
                login_triggerevent: Cookie.get('login_triggerevent'),
                login_method: Cookie.get('login_type'),
              },
            });
            if (curStudent && reserveForm?.grade) {
              reserveSubject({
                subject: reserveForm?.subject?.toUpperCase(),
                info: {
                  timeZoneStandard: reserveForm?.timezone,
                  age: reserveForm?.age,
                  country: reserveForm?.country,
                  courseDate: reserveForm?.date,
                  courseTime: reserveForm?.time,
                  firstName: reserveForm?.name,
                  ...params,
                },
              } as any).then();
            }
            setShowModal(false);
            router.push(PATHS.home).then();
          }}
        >
          {appointmentT('放弃免费约课机会')}
        </WKButton>
      </div> */}
          <div className={styles.modalSimpleContent}>
            <p className={styles.modalTitle}>{appointmentT('确定要放弃约课吗')}</p>
            <p className={styles.modalSub}>{appointmentT('退出信息将不会保存')}</p>
            <WKButton type={'primary'} className={styles.continueAppointBtn} onClick={memorizedHandleModalClose}>
              {appointmentT('继续约课')}
            </WKButton>
            <WKButton
              type={'text'}
              style={{ width: '100%', fontSize: '14px', marginTop: '13px' }}
              onClick={() => {
                const reserveForm = window.sessionStorage.getItem('ReserveForm')
                  ? JSON.parse(window.sessionStorage.getItem('ReserveForm') ?? '')
                  : null;
                const params =
                  reserveForm?.subject === 'math'
                    ? { grade: `${reserveForm?.grade}` }
                    : { chineseLevel: `${reserveForm?.grade}` };
                traceEvent({
                  data: {
                    _event: 'U_BookingPop_GaveUpClick',
                    subject: subject ? subject : window.sessionStorage.getItem('curSubject'),
                    account_id: account?.uuid,
                    booking_triggerevent: source ? 'web_official' : router.query?.booking_triggerevent,
                    trigger_pvid: Cookie.get('trigger_pvid'),
                    login_triggerevent: Cookie.get('login_triggerevent'),
                    login_method: Cookie.get('login_type'),
                  },
                });
                if (curStudent && reserveForm?.grade) {
                  reserveSubject({
                    subject: reserveForm?.subject?.toUpperCase(),
                    info: {
                      timeZoneStandard: reserveForm?.timezone,
                      age: reserveForm?.age,
                      country: reserveForm?.country,
                      courseDate: reserveForm?.date,
                      courseTime: reserveForm?.time,
                      firstName: reserveForm?.name,
                      ...params,
                    },
                  } as any).then();
                }
                setShowModal(false);
                router.push(PATHS.home).then();
              }}
            >
              {appointmentT('放弃免费约课机会')}
            </WKButton>
          </div>
        </WKModal>
      </div>
    </>
  );
};

export default Header;
